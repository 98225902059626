<script>
import { BButton } from 'bootstrap-vue'

import cvData from '../assets/cv.json'

export default {
  name: 'Resume',
  components: {
    BButton
  },
  data () {
    return {
      cv: cvData,
      showMoreExperience: false,
      showMoreExperienceText: 'See',
      showMoreProjects: false,
      showMoreProjectsText: 'See'
    }
  },
  methods: {
    toggleExperienceShowHide (val) {
      this.showMoreExperience = !this.showMoreExperience
      this.showMoreExperienceText = this.showMoreExperience ? 'Hide' : 'See'
    },
    toggleProjectShowHide (val) {
      this.showMoreProjects = !this.showMoreProjects
      this.showMoreProjectsText = this.showMoreProjects ? 'Hide' : 'See'
    }
  }
}
</script>

<template>
  <div class="container">
    <h1>Resume</h1>

    <div class="pdf">
      <a
        href="/assets/BryceResume.pdf"
        title="/assets/BryceResume.pdf"
        target="_blank"
      >Resume PDF</a>
    </div>

    <div id="resume">
      <div class="experience">
        <h3>Professional Experience</h3>
        <div>
          <div
            v-for="xp in cv.experience"
            :key="xp.title"
            :class="{ 'd-none': !showMoreExperience && xp.meta && xp.meta.hidden }"
            class="xp"
          >
            <div class="date">
              {{ xp.date }}
            </div>
            <div class="company">
              <b>{{ xp.company }}</b>
            </div>
            <div class="title">
              <i>{{ xp.title }}</i>
            </div>

            <div
              v-if="xp.description"
              class="description"
            >
              <div v-if="xp.description.intro">
                {{ xp.description.intro }}
              </div>

              <ul v-if="xp.description.items">
                <li
                  v-for="item in xp.description.items"
                  :key="item"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
          <div class="container-btn center">
            <b-button
              size="sm"
              class="show-hide-btn"
              @click="toggleExperienceShowHide"
            >
              {{ showMoreExperienceText }} more
            </b-button>
          </div>
        </div>
      </div>

      <div class="projects">
        <h3>
          Projects
          <span>
            (more info <router-link :to="{ name: 'projects' }">here</router-link>)
          </span>
        </h3>
        <div>
          <div
            v-for="proj in cv.projects"
            :key="proj.title"
            :class="{ 'd-none': !showMoreProjects && proj.meta && proj.meta.hidden }"
          >
            <div class="date">
              {{ proj.date }}
            </div>
            <div class="title">
              <i>{{ proj.title }}</i>
            </div>
            <div v-if="proj.description">
              <ul>
                <li
                  v-for="desc in proj.description"
                  :key="desc"
                >
                  {{ desc }}
                </li>
              </ul>
            </div>
          </div>
          <div class="container-btn center">
            <b-button
              size="sm"
              class="show-hide-btn"
              @click="toggleProjectShowHide"
            >
              {{ showMoreProjectsText }} more
            </b-button>
          </div>
        </div>
      </div>

      <div class="skills">
        <h3>Languages and Technologies (# years)</h3>
        <div>
          <ul>
            <li
              v-for="skills in cv.skills"
              :key="skills"
            >
              {{ skills }}
            </li>
          </ul>
        </div>
      </div>

      <div class="education">
        <h3>Education</h3>
        <div>
          <div class="date">
            {{ cv.education.date }}
          </div>
          <div>{{ cv.education.school }}</div>
          <div><i>{{ cv.education.major }}</i></div>
          <div><i>{{ cv.education.minor }}</i></div>
        </div>
      </div>

      <div class="accolades">
        <h3>Extracurricular + Achievements</h3>
        <div>
          <ul>
            <li
              v-for="extra in cv.extracurricular.description"
              :key="extra"
            >
              {{ extra }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.container {
  .pdf {
    margin-bottom: 1.5em;
  }

  #resume {
    text-shadow: none;
    text-align: left;
    padding: 10px;
    color: #000;
    background-color: #fff;
    -moz-box-shadow: 2px 2px 4px 2px #000;
    -webkit-box-shadow: 2px 2px 4px 2px #000;
    box-shadow: 2px 2px 4px 2px #000;

    * {
      color: #000;
    }

    a:focus, a:hover {
      color: #b0172e;
    }

    .show-hide-btn {
      color: #fff;
      outline: none;
      box-shadow: none;
    }

    .container-btn {
      margin-top: 1em;
    }

    div[class*=date] {
      float: right;
    }

    .experience, .projects, .skills, .education, .accolades {
      margin: 0 0 5px 0;
    }

    .projects, .skills, .education, .accolades {
      margin-top: 1.5em;
    }

    h3 {
      border-bottom: 1px solid black;
      text-transform: uppercase;
      margin-bottom: 4px;
      font-size: 1.5em;

      span {
        text-transform: lowercase;
      }
    }

    .xp {
      .date + .company {
        margin: 14px 0 0 0;
      }
      > div:nth-of-type(n + 2) {
        margin: 4px 0;
      }
      .description ul {
        margin: 4px 0;

        li {
          margin: 2px 0;
        }
      }
    }
  }
}
</style>
